import axios from "@/axios.js"

export default {

    AddAppointmentMarkup({ commit }, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios.post("api/AppointmentMarkup/AddAppointmentMarkup", item)
                .then((response) => {
                    commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetAllAppointmentMarkups({ commit }, search) {
        debugger;
        return new Promise((resolve, reject) => {
            axios.post("api/AppointmentMarkup/SearchAppointmentMarkups", search).then((response) => {
                    commit('SET_AppointmentMarkup', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    UpdateAppointmentMarkup(context, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("api/AppointmentMarkup/UpdateAppointmentMarkup", item)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },




    GetAppointmentMarkup({ commit }, itemid) {
        return new Promise((resolve, reject) => {
            axios.get("api/AppointmentMarkup/GetAppointmentMarkup?ID=" + itemid)
                .then((response) => {
                    debugger
                    //commit('UPDATE_AppointmentMarkup', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    DeleteAppointmentMarkup({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.delete("api/AppointmentMarkup/DeleteAppointmentMarkup?ID=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
}